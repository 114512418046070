.landing-pageBox {
  padding: 2rem;
  padding-right: 38.125rem;
  width: 100%;
}
.landing-pageBox__logo {
  width: 6.4375rem;
}
.landing-pageBox__banner {
  margin: 6rem 3.75rem 0 0;
  width: 43.5rem;
}

.signinBox {
  position: fixed;
  right: 0;
  top: 0;
  width: 30.125rem;
  min-width: 30.125rem;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-left: 6px solid #FA00A0;
}
.signinBox__heading {
  padding: 2rem;
  font-size: 1.875rem;
  text-align: center;
  color: #000;
  text-transform: uppercase;
  /* font-family: "Avenir Next Bold"; */
  /* background: #FA00A0; */
  background: url(./assets/headingbackground.jpg);
  background-size: 100%;

  display: flex;
  justify-content: center;
  /* border-bottom: 0.3rem solid #9AE000; */
  width: 100%;
}

.login-input {
  border: 3px solid #FA00A0;
  color: #222121;
  background: #fff;
  font-size: 0.95rem;
  font-weight: 700;
  border-radius: 0;
}

.br-l {
  border-left: 0.5rem solid #9AE000 !important;
}
.br-lPurple {
  border-left: 0.7rem solid #FA00A0 !important;
}
.login-btn {
  background: #1D005B;
  color: #fff;
  border: none;
  font-weight: bold;
}
.login-btn:hover {
  transform: scale(1.1);
  transition: 0.1s;
}

.flx-cntr {
  justify-content: center;
}
.cntr {
  text-align: center;
}

.signinBox__heading__container {
  width: 100%;
  display: flex;
  border-bottom: 2px solid #9AE000;
}

.bg-img {
  background-size: 100%;
  background-image: url("./assets/head_bg.jpg");
}
:-ms-input-placeholder {
  color: #000;
}
::-webkit-input-placeholder {
  color: #000;
}
.signinBox__heading__gradient {
  width: 0%;
}

.signinBox__heading div {
  width: 30rem;
  height: 10rem;
}
.signinBox__heading .left {
  background: url(./assets/header.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.signinBox__heading .right {
  background: url(./assets/koaLogo.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.skipContianer-n {
  position: absolute;
  bottom: 0rem;
  width: 100%;
  height: 5rem;
  padding: 0.5rem 1rem;
  opacity: 0.8;
  background-color: rgba(0, 0, 0, 0.527);
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  animation: skipContianer 0.5s forwards;
  animation-delay: 1s;
  transition: 0.15s ease-in;
}
.skipContianer-n:hover {
  opacity: 1;
}
.skipBtn-n {
  padding: 0.85rem 1.85rem;
  background-color: #FA00A0;
  color: #fff;
  font-size: 1.25rem;
  border-radius: 1.5rem;
  cursor: pointer;
}
.signinBox__body {
  padding: 2.125rem 1.875rem 2.125rem 1.875rem;
  overflow: auto;
  height: 100%;
  position: relative;
  max-height: 70vh;
  padding-bottom: 16rem !important;
}
.successful-registration {
  padding: 4rem;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.9);
  opacity: 0.9;
  z-index: 10;
}

.loaderContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #FA00A0;
  font-size: 2.5rem;
  font-weight: 500;
  text-transform: uppercase;
}
.loaderContainer img {
  width: 6rem;
}
.loaderContainer-msg {
  text-align: left;
  font-size: 1.3rem;
  padding: 1rem 2rem;
}
.loaderContainer-msg h3 {
  color: #ee2326;
  text-align: initial;
  font-weight: bold;
}

/* Desktop */
@media only screen and (max-width: 991px) {
  .loaderContainer {
    font-size: 1.2rem;
  }
  .signinBox {
    min-width: 0;
    width: 40%;
    /* position: static; 
    height: 100vh;
    max-height: 100vh;
    min-height: 38rem;
    overflow: auto; */
  }
  .landing-pageBox {
    /* padding: 1.35rem; */
    /* min-height: auto;
    height: 40%;
    height: 35vh;
    max-height: 100vh; */
    /* min-height: 15rem; */
  }
  .landing-pageBox__banner {
    margin: 1.5rem 1rem 0 0;
    width: 20rem;
  }
  .landing-pageBox__logo {
    width: 4.25rem;
  }
  .signinBox__body {
    padding: 2rem;
    overflow: auto;
    height: 70rem;
  }

  .signinBox__heading {
    padding: 1rem;
  }

  .signinBox__heading div {
    width: 16rem;
    height: 7.5rem;
  }

  .pd-t70 {
    padding-top: 2rem !important;
  }
}

/* Tablet */
@media only screen and (max-width: 767px) {
}
