.gallery {
  height: 100vh;
  width: 100%;

  height: 100vh;
  width: 100%;
  /* background-color: #eff1ef; */

  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;

  z-index: 50;

  /* background-image: url("/3dAssets/images/Lobby.jpg"); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.gallery__container {
  border-radius: 0.2rem;
  height: auto;
  width: unset;
  max-width: 80%;
  background: #ebebeb;
  border-bottom: 6px solid #FA00A0;
  box-shadow: 0px 3px 30px #000;
}
.gallery__header {
  height: 9vh;
  background-color: #1D005B;
  box-shadow: 0px 3px 8px 3px rgb(0 0 0 / 30%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.4rem 2rem;
}

.header-title h3 {
  color: #fff;
  font-size: 2rem;
}

.back-btn {
  cursor: pointer;
  transition: 0.25s ease-in;
  transform: scale(0.9);
}

.back-btn:hover {
  transform: scale(1);
}

.gallery__header > h3 {
  font-size: 2rem;
  color: #fff;
}

.gallery__header img {
  max-height: 60%;
}
.gallery__area {
  padding: 3rem 2rem 4rem 2rem;
  max-height: 85vh;
  height: unset;
  overflow-y: auto;
  background: #fff;
}

.gallery__area .header-title {
  text-align: center;
  padding-bottom: 1rem;
  color: #1D005B;
  font-weight: bold;
}

.gallery__area h1 {
  font-size: 3.5rem;
  margin-bottom: 2rem;
}
.box__img {
  height: 25vh;
  width: 25vh;
  position: relative;
  cursor: pointer;
  min-height: 10rem;
  min-width: 10rem;
}
.gallery__row {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  justify-content: center;
}
.gallery__popup {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  min-height: fit-content;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1;
  animation: anim 0.5s ease-in-out;
}
.inner__popup {
  display: flex;
  height: 50vh;
  min-height: 15rem;
}

.image__left {
  flex: 0.3;
  background-color: #e2e3e2;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.closePopup {
  position: absolute;
  left: 0;
  top: 0;
  height: 2rem;
  width: 2rem !important;
  margin: 0.5rem;
  cursor: pointer;
}
.image__left img {
  width: 60%;
}
.desc__right {
  flex: 0.7;
  background-color: #fff;
  max-height: 100%;
  overflow-y: auto;
  padding: 2rem 2rem 3rem 2rem;
}
.desc__right p {
  margin-bottom: 2rem;
  font-size: 1.2rem;
}
.desc__right h2 {
  margin-bottom: 2rem;
  font-size: 1.7rem;
}
@keyframes anim {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.box {
  width: fit-content;
  margin: 0 0.25rem 1rem;
  /* margin-bottom: 3rem; */
  max-width: 25vh;
  box-shadow: 0px 0px 0.1rem #000;
}
.box p {
  /* margin-top: 1rem; */
  font-size: 1rem;
  text-align: center;
  padding: 0.7rem 0.6rem;
  background: #FA00A0;
  color: #fff;
}
.hover__bg {
  position: absolute;
  flex-direction: column;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  /* background-color: rgba(255, 255, 255, 0.75); */
  transform: scaleY(0);
  opacity: 0;
  transition: 0.25s ease-in-out;
  transform-origin: top;
}

.sub_hover_bg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 90%;
  background: #1D005B;
  color: #fff;
}

.hover__bg h4 {
  width: 82%;
  text-align: center;
  font-size: 0.9rem;
}

.hover__bg img {
  width: 50%;
}
.box__img:hover .hover__bg {
  transform: scaleY(1);
  opacity: 1;
}
.box__img__bg {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
@media (max-width: 991px) {
  .gallery {
    height: calc(100vh);
    align-items: flex-start;
  }
  .gallery__container {
    margin-right: 7rem;
    width: 72%;
    margin-top: 2rem;
  }

  .gallery__header > h3 {
    font-size: 1.5rem;
  }

  .hover__bg h4 {
    font-size: 0.7rem;
  }
  .gallery__area {
    max-height: calc(85vh - 5.3rem);
    padding: 1.2rem 1.5rem 4rem 1.5rem;
  }

  .gallery__area > h3 {
    font-size: 1rem;
    width: 100%;
  }

  .desc__right h2 {
    font-size: 1.45rem;
  }
  .gallery__area h1 {
    font-size: 2.5rem;
  }
  .gallery__header img {
    max-height: 100%;
  }
  .closeNotice {
    height: 3rem;
  }
  .box {
    max-width: 10rem;
    margin-bottom: 1rem;
  }
}
