.videoPlayerCover {
  position: absolute !important;
  /* transition: 0.4s; */
}

.blackTransparentColor {
  background: rgba(0, 0, 0, 0.418);
}
.videoControlsContainer-fullscreen.videoControlsContainer-b-1 {
  /* left: 1rem !important; */
}
@media (max-width: 991px) {
  .videoControlsContainer-fullscreen.videoControlsContainer-b-1 {
    /* left: auto !important; */
    /* right: -4rem !important; */
  }
}
.lid {
  background: black;
  position: absolute;
  width: 100%;
  height: 45%;
  opacity: 1;
  pointer-events: none;
}

.upperLid {
  top: 0;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(8, 8, 8, 1) 20%,
    rgba(0, 0, 0, 1) 100%
  );
}

.lowerLid {
  bottom: 0;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(8, 8, 8, 1) 20%,
    rgba(0, 0, 0, 1) 100%
  );
}
.am_pm_div {
  position: fixed;
  z-index: 11;
  right: 0;
  top: 0;
  margin: 1rem;
  margin-right: 8rem;
  height: fit-content;
  width: fit-content;
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 0.5rem 1rem;
  backdrop-filter: blur(0.4rem);
  border-radius: 0.3rem;
}
.scroll__container {
  position: fixed;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  right: 0;
  bottom: 0;
  margin: 1rem 2rem;
  z-index: 17;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: scale 2s infinite;
}
.scroll__container span {
  transform: rotate(90deg);
  font-size: 20px;
  margin-left: 5px;
  /* animation: 1.5s scroll infinite; */
}
@keyframes scroll {
  0% {
    font-weight: normal;
    ont-size: 20px;
  }
  50% {
    font-weight: bolder;
    ont-size: 25px;
  }
  100% {
    font-weight: normal;
    ont-size: 20px;
  }
}
@keyframes scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.animateLid {
  height: 0%;
  opacity: 0.8;
  transition: 8s;
}

.animateLidClose {
  height: 35%;
  transition: 4s;
}

.animateLid-0 {
  height: 0%;
  opacity: 0;
  animation: unset;
}

.fakePlaybutton {
  width: 9vh;
  height: 9vh;
  background: url("./assets/PlayButton.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  animation: play-button-ripple 0.7s linear infinite;
  border-radius: 50%;
  transition: 0.5s;
  cursor: pointer;
}

.fakePlaybutton-container {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes play-button-ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.3),
      0 0 0 0.8em rgba(255, 255, 255, 0.3), 0 0 0 1.6em rgba(255, 255, 255, 0.3);
  }
  100% {
    box-shadow: 0 0 0 0.8em rgba(255, 255, 255, 0.3),
      0 0 0 1.6em rgba(255, 255, 255, 0.3), 0 0 0 2.4em rgba(255, 255, 255, 0);
  }
}

.inlineVideoControlsContainer {
  height: 100%;
  background: white;
  width: 0;
}

.inlineVideoControlsContainer img {
  bottom: 0;
}

.inlineVideoControlsIcon {
  max-width: 1rem;
}
